import React, { useState, useEffect } from "react";
import { AddButton } from "../UI/StyledComponents";
import { PageHeader, PageSubtitle, PageWrapper, PageTitle } from "../UI/StyledComponents";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { getStudents, newProfileCorrection } from "../../Api/center";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AddProfileCorrectionComponent = () => {
  const { centerInfo } = useSelector((state) => state.auth);
  const [selectedField, setSelectedField] = useState("");
  const [correctionValue, setCorrectionValue] = useState("");
  const [students, setStudents] = useState([]);
  const [studentId, setStudentId] = useState('');
  const [centerId, setCenterId] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    const decoding = async () => {
      if (centerInfo) {
        const decodedToken = jwtDecode(centerInfo);
        if (decodedToken && decodedToken.id) {
          setCenterId(decodedToken.id);
        }
      }
    };
    decoding();
  }, [centerInfo]);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const res = await getStudents();
        if (res.data.success) {
          const filteredStudents = res.data.data.filter(
            (student) => student.center_id === centerId
          );
          setStudents(filteredStudents);
        }
      } catch (error) {
        console.log(error)
      }
    }
    fetchStudentData()
  }, [centerId])

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      let formattedCorrectionValue = correctionValue;
      if (selectedField === "date_of_birth") {
        const date = new Date(correctionValue);
        formattedCorrectionValue = date.toISOString();
      }
      const res = await newProfileCorrection(studentId, selectedField, formattedCorrectionValue)
      if (res.data.success) {
        toast.success(res.data.message)
        navigate('/center/corrections')
      } else if (!res.data.success) {
        toast.error(res.data.message)
      }
    } catch (error) {
      console.log(error)
    }
  };

  const handleFieldChange = (e) => {
    setSelectedField(e.target.value);
    setCorrectionValue("");
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Profile Corrections</PageTitle>
        <PageSubtitle>Add a student profile correction request</PageSubtitle>
      </PageHeader>
      <div className="max-w-8xl mx-auto p-6 bg-white shadow-md rounded-lg">
        <div className="mt-6">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Select Student
          </label>
          <select
            className="w-full p-2 border rounded-md"
            value={studentId}
            onChange={(e) => setStudentId(e.target.value)}
          >
            <option value="">Select Student</option>
            {students.map((student) => (
              <option key={student.id} value={student.id}>
                {student.roll_number} - {student.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <div className="mt-6">
            <label className="block text-gray-700 text-sm font-medium mb-2">
              Select Correction Field
            </label>
            <select
              className="w-full p-2 border rounded-md"
              value={selectedField}
              onChange={handleFieldChange}
            >
              <option value="">Select Field</option>
              <option value="name">Student Name</option>
              <option value="father_name">Father's Name</option>
              <option value="mother_name">Mother's Name</option>
              <option value="date_of_birth">Date of Birth</option>
              <option value="address">Address</option>
              <option value="email">Email</option>
              <option value="mobile_number">Mobile Number</option>
            </select>
          </div>

          <div className="mt-6">
            <table className="min-w-full border-collapse">
              <thead>
                <tr className="bg-gray-100 text-left text-sm font-semibold">
                  <th className="p-4">Correction Value</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td className="p-4">
                    {selectedField === "date_of_birth" ? (
                      <input
                        type="date"
                        className="w-full p-2 border rounded-md"
                        value={correctionValue}
                        onChange={(e) => setCorrectionValue(e.target.value)}
                      />
                    ) : (
                      <input
                        type="text"
                        className="w-full p-2 border rounded-md"
                        value={correctionValue}
                        onChange={(e) => setCorrectionValue(e.target.value)}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex justify-end mt-6">
          <AddButton
            className='bg-blue-500 text-white px-6 py-2 rounded-md'
            onClick={handleSubmit}
          >
            Submit
          </AddButton>
        </div>
      </div>
    </PageWrapper>
  );
};

export default AddProfileCorrectionComponent;