import Api from "../Service/axios";
import AdminEndpoint from "../Service/endpoints/adminEndpoints";
import AuthEndpoint from "../Service/endpoints/authEndpoints";

export const employees = async () => {
  try {
    const res = await Api.get(AdminEndpoint.staff);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addStaff = async (
  username,
  password,
  mobile_number,
  email,
  full_name,
  role_name,
  privileges
) => {
  try {
    const res = await Api.post(AdminEndpoint.staff, {
      username,
      password,
      mobile_number,
      email,
      full_name,
      role_name,
      privileges,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchStaff = async (id) => {
  try {
    const res = await Api.get(`${AdminEndpoint.staff}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editStaff = async (
  id,
  username,
  email,
  full_name,
  mobile_number,
  privileges
) => {
  try {
    const res = await Api.put(`${AdminEndpoint.staff}/${id}`, {
      username,
      email,
      full_name,
      mobile_number,
      privileges,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const disableStaff = async (id) => {
  try {
    const res = await Api.post(`${AdminEndpoint.staff}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const centers = async () => {
  try {
    const res = await Api.get(AdminEndpoint.center);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const disableCenter = async (id) => {
  try {
    const res = await Api.patch(`${AdminEndpoint.center}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addCenter = async (formData) => {
  try {
    const res = await Api.post(AdminEndpoint.center, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editCenter = async (
  id,
  center_name,
  center_code,
  address,
  mobile_number,
  email,
  coordinator_name,
  address_line1,
  address_line2,
  address_line3
) => {
  try {
    const res = await Api.put(`${AdminEndpoint.center}/${id}`, {
      center_name,
      center_code,
      address,
      mobile_number,
      email,
      coordinator_name,
      address_line1,
      address_line2,
      address_line3,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchCenter = async (id) => {
  try {
    const res = await Api.get(`${AdminEndpoint.center}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const student = async () => {
  try {
    const res = await Api.get(AdminEndpoint.student);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const subjects = async () => {
  try {
    const res = await Api.get(AdminEndpoint.subject);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const disableSubject = async (id) => {
  try {
    const res = await Api.patch(`${AdminEndpoint.subject}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addSubject = async (
  subject_code,
  subject_name,
  max_marks,
  min_marks,
  practical_marks
) => {
  try {
    const res = await Api.post(AdminEndpoint.subject, {
      subject_code,
      subject_name,
      max_marks,
      min_marks,
      practical_marks,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSubject = async (id) => {
  try {
    const res = await Api.get(`${AdminEndpoint.subject}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editSubject = async (
  id,
  subject_name,
  subject_code,
  description,
  max_marks,
  min_marks,
  practical_marks
) => {
  try {
    const res = await Api.put(`${AdminEndpoint.subject}/${id}`, {
      subject_name,
      subject_code,
      description,
      max_marks,
      min_marks,
      practical_marks,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const courses = async () => {
  try {
    const res = await Api.get(AdminEndpoint.course);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const disableCourse = async (id) => {
  try {
    const res = await Api.patch(`${AdminEndpoint.course}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addCourse = async (course_name, selectedSubjects, courseDuration, examId, examType) => {
  try {
    const res = await Api.post(AdminEndpoint.course, { course_name, selectedSubjects, courseDuration, examId, examType });
    return res
  } catch (error) {
    console.log(error)
  }
}

export const fetchCourse = async (id) => {
  try {
    const res = await Api.get(`${AdminEndpoint.course}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editCourse = async (id, course_name, course_duration, selectedSubjects, examId, examType) => {
  try {
    const res = await Api.put(`${AdminEndpoint.course}/${id}`, { course_name, course_duration, selectedSubjects, examId, examType });
    return res
  } catch (error) {
    console.log(error)
  }
}

export const sessions = async () => {
  try {
    const res = await Api.get(AdminEndpoint.session);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addSession = async (session_month, session_year, issue_date) => {
  try {
    const res = await Api.post(AdminEndpoint.session, {
      session_month,
      session_year,
      issue_date,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSession = async (id) => {
  try {
    const res = await Api.get(`${AdminEndpoint.session}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editSession = async (
  id,
  session_month,
  session_year,
  issue_date
) => {
  try {
    const res = await Api.put(`${AdminEndpoint.session}/${id}`, {
      id,
      session_month,
      session_year,
      issue_date,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const exams = async () => {
  try {
    const res = await Api.get(AdminEndpoint.exam);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addExam = async (exam_name, exam_type) => {
  try {
    const res = await Api.post(AdminEndpoint.exam, { exam_name, exam_type })
    return res
  } catch (error) {
    console.log(error)
  }
}

export const fetchExam = async (id) => {
  try {
    const res = await Api.get(`${AdminEndpoint.exam}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editExam = async (id, exam_name) => {
  try {
    const res = await Api.put(`${AdminEndpoint.exam}/${id}`, {
      id,
      exam_name
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSubjectsOfCourses = async (courseId) => {
  try {
    const res = await Api.get(
      `${AdminEndpoint.subject}/getByCourse/${courseId}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addTimetable = async (payload) => {
  try {
    const res = await Api.post(AdminEndpoint.timetable, { payload });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const timetables = async () => {
  try {
    const res = await Api.get(AdminEndpoint.timetable);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTimetable = async (id) => {
  try {
    const res = await Api.get(`${AdminEndpoint.timetable}/${id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const editTimetable = async (id, payload) => {
  try {
    const res = await Api.put(`${AdminEndpoint.timetable}/${id}`, { payload });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const changePassword = async (id, oldPassword, newPassword, role) => {
  try {
    const res = await Api.post(`${AuthEndpoint.changePassword}`, {
      id,
      oldPassword,
      newPassword,
      role,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateResults = async (payload) => {
  try {
    const res = await Api.post(AdminEndpoint.result, payload);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchOverallStatus = async (examId, rollNumber) => {
  try {
    const res = await Api.get(
      `${AdminEndpoint.result}?examId=${examId}&rollNumber=${rollNumber}`
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

export const checkAllResultsPublished = async (examId) => {
  try {
    const res = await Api.get(`${AdminEndpoint.result}/isPublished/${examId}`);
    return res
  } catch (error) {
    console.log(error)
  }
}

export const fetchStudent = async (rollNumber) => {
  try {
    const res = await Api.get(
      `${AdminEndpoint.student}/getStudentByRollNumber/${rollNumber}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchResultsByExamId = async (examId) => {
  try {
    const res = await Api.get(
      `${AdminEndpoint.result}/getResultByExamId/${examId}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const publishResults = async (examId) => {
  try {
    const res = await Api.post(`${AdminEndpoint.result}/publish/${examId}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addTask = async (
  title,
  staffId,
  priority,
  dueDate,
  description
) => {
  try {
    const res = await Api.post(AdminEndpoint.task, {
      title,
      staffId,
      priority,
      dueDate,
      description,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllTasks = async () => {
  try {
    const res = await Api.get(AdminEndpoint.task);
    return res;
  } catch (error) {
    console.log("Error fetching tasks:", error);
    return null;
  }
};

export const fetchOriginalRequests = async () => {
  try {
    const res = await Api.get(`${AdminEndpoint.printRequest}/original`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDuplicateRequests = async () => {
  try {
    const res = await Api.get(`${AdminEndpoint.printRequest}/duplicate`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateRequestStatus = async (requestId, status, type) => {
  try {
    const res = await Api.patch(`${AdminEndpoint.printRequest}/${requestId}`, {
      status,
      type,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const dashboardData = async () => {
  try {
    const res = await Api.get(AdminEndpoint.admin);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchProfileCorrections = async () => {
  try {
    const res = await Api.get(`${AdminEndpoint.correction}/profile`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const approveProfileCorrection = async (id) => {
  try {
    const res = await Api.put(`${AdminEndpoint.correction}/acceptProfile/${id}`);
    return res
  } catch (error) {
    console.log(error)
  }
}

export const rejectProfileCorrection = async (id) => {
  try {
    const res = await Api.put(`${AdminEndpoint.correction}/rejectProfile/${id}`);
    return res
  } catch (error) {
    console.log(error)
  }
}

export const fetchResultCorrections = async () => {
  try {
    const res = await Api.get(`${AdminEndpoint.correction}/result`);
    return res
  } catch (error) {
    console.log(error)
  }
}

export const approveResultCorrection = async (id) => {
  try {
    const res = await Api.put(`${AdminEndpoint.correction}/acceptResult/${id}`);
    return res
  } catch (error) {
    console.log(error)
  }
}

export const rejectResultCorrection = async (id) => {
  try {
    const res = await Api.put(`${AdminEndpoint.correction}/rejectResult/${id}`);
    return res
  } catch (error) {
    console.log(error)
  }
}

export const updateStudentStatus = async (studentId, status) => {
  try {
    const res = await Api.put(`${AdminEndpoint.student}/changeStatus/${studentId}`, { status })
    return res
  } catch (error) {
    console.log(error)
  }
}

export const updateStudentRollNumber = async (studentId, rollNumber) => {
  try {
    const res = await Api.put(`${AdminEndpoint.student}/assignRollNumber/${studentId}`, { rollNumber });
    return res
  } catch (error) {
    console.log(error)
  }
}

export const fetchStudentsByExam = async (examId) => {
  try {
    const res = await Api.get(`${AdminEndpoint.student}/getByExamId/${examId}`)
    return res
  } catch (error) {
    console.log(error)
  }
}

export const fetchAllPrivileges = async (staffId) => {
  try {
    const res = await Api.get(`${AdminEndpoint.privileges}/staff/${staffId}`);
    return res;
  } catch (error) {
    console.error("Error fetching all privileges:", error);
  }
};

export const updatePrivileges = async (staffId, privilegeId, newStatus) => {
  try {
    const res = await Api.put(
      `${AdminEndpoint.privileges}/staff/${staffId}/update`,
      {
        privilegeId,
        newStatus,
      }
    );
    return res;
  } catch (error) {
    console.error("Error updating privileges:", error);
  }
};

export const changePasswordByAdmin = async (id, password, role) => {
  try {
    const res = await Api.put(
      `${AuthEndpoint.changePasswordByAdmin}/${id}`,
      { password, role }
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCertificate = async (id, requestType) => {
  try {
    const res = await Api.get(`${AdminEndpoint.printRequest}/originalCertificate/${id}/${requestType}`);
    return res
  } catch (error) {
    console.log(error)
  }
}

export const fetchSessionByCourseId = async (courseId) => {
  try {
    const res = await Api.get(`${AdminEndpoint.session}/getByCourseId/${courseId}`)
    return res
  } catch (error) {
    console.log(error)
  }
}

export const getExamByRollNumber = async (rollNumber) => {
  try {
    const res = await Api.get(`${AdminEndpoint.exam}/getByRollNumber/${rollNumber}`)
    return res
  } catch (error) {
    console.log(error)
  }
}

export const getStudentById = async (id) => {
  try {
    const res = await Api.get(`${AdminEndpoint.student}/${id}`)
    return res
  } catch (error) {
    console.log(error)
  }
}

export const addStudent = async (formData) => {
  try {
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    const res = await Api.post(AdminEndpoint.upload, formData, config);
    return res;
  } catch (error) {
    console.log(error)
  }
};