import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getStudentById } from "../../Api/admin";
import UserImage from "../../Assets/userImage.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  ProfileCard,
  ProfileImageWrapper,
  ProfileInfo,
  ProfileName,
  StudentDetailsCard,
  InfoCard,
  InfoTitle,
  InfoList,
  PageTitle,
  PageSubtitle,
  InfoListItem,
  PageHeader,
  PageWrapper,
} from "../UI/StyledComponents";

const ViewStudentComponent = () => {
  const [students, setStudent] = useState(null);
  const { id } = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        if (id) {
          const res = await getStudentById(id);
          if (res.data.success) {
            setStudent(res.data.data.student);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchStudentData();
  }, [id]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Student Profile</PageTitle>
        <PageSubtitle>View personal and academic details</PageSubtitle>
      </PageHeader>
      {students && (
        <>
          <ProfileCard>
            <div className="relative mb-5 mt-6">
              <ProfileImageWrapper>
                <img src={students?.image[0] || UserImage} alt="Profile" />
              </ProfileImageWrapper>
            </div>
            <ProfileInfo>
              <div>
                <ProfileName>{students?.name}</ProfileName>
              </div>
            </ProfileInfo>
          </ProfileCard>
          <StudentDetailsCard>
            <InfoCard>
              <InfoTitle>Personal Details</InfoTitle>
              <InfoList>
                <InfoListItem>Student Name : {students?.name}</InfoListItem>
                <InfoListItem>
                  Father's Name : {students?.father_name}
                </InfoListItem>
                <InfoListItem>
                  Mother's Name : {students?.mother_name}
                </InfoListItem>
                <InfoListItem>Caste : {students?.caste}</InfoListItem>
                <InfoListItem>Category : {students?.category}</InfoListItem>
                <InfoListItem>
                  Date of Birth : {formatDate(students?.date_of_birth)}
                </InfoListItem>
                <InfoListItem>Gender : {students?.gender}</InfoListItem>
                <InfoListItem>Address : {students?.address}</InfoListItem>
                <InfoListItem>
                  Admission Status : {students?.admission_status}
                </InfoListItem>
                <InfoListItem>
                  Mobile number : {students?.mobile_number}
                </InfoListItem>
                <InfoListItem>Email : {students?.email}</InfoListItem>
              </InfoList>
            </InfoCard>
            <InfoCard>
              <InfoTitle>Academic Details</InfoTitle>
              <InfoList>
                <InfoListItem>
                  Center Name : {students?.center?.center_name}
                </InfoListItem>
                <InfoListItem>
                  Course : {students?.course?.course_name}
                </InfoListItem>
                <InfoListItem>
                  Roll No :{" "}
                  {students?.roll_number
                    ? students?.roll_number
                    : "Not assigned yet"}
                </InfoListItem>
                <InfoListItem>
                  Admission Status : {students?.admission_status}
                </InfoListItem>
                <InfoListItem>
                  Session : {students?.session?.session_year} -{" "}
                  {students?.session?.session_month}
                </InfoListItem>
                <InfoListItem>
                  Last School attended : {students?.lastSchool}
                </InfoListItem>
                {students?.otherInfo && (
                  <InfoListItem>Other Info: {students.otherInfo}</InfoListItem>
                )}
              </InfoList>
            </InfoCard>
          </StudentDetailsCard>
          <StudentDetailsCard>
            <InfoCard>
              <InfoTitle>Student Verification Documents</InfoTitle>
              <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {students?.image?.slice(1).map((image, index) => (
                  <div
                    key={index}
                    style={{
                      width: "150px",
                      height: "150px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                    onClick={() => openModal(image)}
                  >
                    <img
                      src={image}
                      alt={`Student ${index + 1}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ))}
              </div>
            </InfoCard>
          </StudentDetailsCard>

          {modalIsOpen && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
              onClick={closeModal}
            >
              <div className="relative max-w-2xl max-h-[90vh] mx-auto p-4 bg-white rounded-lg shadow-lg">
                <button
                  title="Close"
                  onClick={closeModal}
                  className="absolute top-0 right-0 mt-2 mr-2 text-white bg-red-600 rounded-full w-8 h-8 flex items-center justify-center"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <img
                  src={selectedImage}
                  alt="Enlarged Verification File"
                  className="w-full h-auto max-h-[85vh] rounded-md"
                />
              </div>
            </div>
          )}
        </>
      )}
    </PageWrapper>
  );
};
export default ViewStudentComponent;
