import React, { useRef, useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import logo from "../../Assets/logo.png";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import { fetchTimetable, fetchSubjectsOfCourses, fetchCenter, fetchCourse } from '../../Api/student';
import UserImage from '../../Assets/userImage.png';
import { AddButton } from '../UI/StyledComponents';

const ViewHallticketComponent = () => {
    const hallTicketRef = useRef();
    const [timetable, setTimetable] = useState([]);
    const [subjects, setSubjects] = useState([]);
    const { studentInfo } = useSelector((state) => state.auth);
    const [student, setStudent] = useState();
    const [centerId, setCenterId] = useState('');
    const [center, setCenter] = useState();
    const [studentImage, setStudentImage] = useState('');
    const [course_id, setCourseId] = useState(null);
    const [allSubjects, setAllSubjects] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        const decoding = () => {
            if (studentInfo) {
                const decodedToken = jwtDecode(studentInfo);
                if (decodedToken && decodedToken.id) {
                    setStudent(decodedToken.user);
                    setCenterId(decodedToken.user.center_id);
                    setCourseId(decodedToken.user.course_id);
                    const imageUrl = decodedToken.user.image[0] || UserImage;
                    loadImage(imageUrl);
                }
            }
        };
        decoding();
    }, [studentInfo]);

    const loadImage = (url) => {
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = url;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            setStudentImage(dataURL);
        };
        img.onerror = (err) => {
            console.error('Error loading image', err);
            setStudentImage(UserImage);
        };
    };

    useEffect(() => {
        const fetchCenterData = async () => {
            try {
                if (centerId) {
                    const res = await fetchCenter(centerId);
                    if (res.data.success) {
                        setCenter(res.data.data);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchCenterData();
    }, [centerId]);

    useEffect(() => {
        const fetchTimetableData = async () => {
            if (id) {
                const res = await fetchTimetable(id);
                if (res.data.success) {
                    const timetableData = res.data.data.timetable;
                    setTimetable(timetableData);
                }
            }
        };
        fetchTimetableData();
    }, [id]);

    const handlePrint = () => {
        const input = hallTicketRef.current;
        html2canvas(input, {
            backgroundColor: "#ffffff",
            scale: 2,
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "mm", "a4");
            pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
            pdf.save("hall_ticket.pdf");
        });
    };

    function convertTo12HourFormat(time24) {
        let [hour, minute] = time24.split(':');
        hour = parseInt(hour);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12 || 12;
        return `${hour}:${minute} ${ampm}`;
    }

    useEffect(() => {
        const fetchCourseData = async () => {
            if (course_id) {
                const res = await fetchCourse(course_id);
                if (res.data.success) {
                    const courseData = res.data.data.course;
                    const subjectIds = courseData?.subjectIds;
                    const matchedSubjects = subjectIds
                        .map((id) => allSubjects.find((subject) => subject.id === id))
                        .filter((subject) => subject);

                    setSubjects(matchedSubjects);
                }
            }
        };
        fetchCourseData();
    }, [course_id, allSubjects]);

    useEffect(() => {
        const fetchSubjects = async () => {
            try {
                if (course_id) {
                    const res = await fetchSubjectsOfCourses(course_id);
                    if (res.data.success) {
                        setAllSubjects(res.data.data);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchSubjects();
    }, [course_id]);

    return (
        <div className="flex justify-center items-center flex-col p-5 bg-white">
            <div
                ref={hallTicketRef}
                className="bg-white border border-gray-400 w-[210mm] h-[297mm] p-8 shadow-none mb-4"
            >
                <div className="flex justify-between items-center">
                    <img src={logo} alt="Logo" className="w-20 h-16" />
                    <div className="text-center">
                        <h1 className="text-lg font-bold uppercase">Karnataka State Council of Intermediate and Higher Education</h1>
                        <h2 className="text-md font-semibold uppercase">Hall Ticket </h2>
                        <h3 className="text-md font-medium">
                            {timetable?.exam?.exam_name && timetable?.session?.session_month && timetable?.session?.session_year
                                ? `${timetable.exam.exam_name}, ${timetable.session.session_month}-${timetable.session.session_year}`
                                : 'Loading...'}
                        </h3>
                    </div>
                </div>

                <div className="flex justify-between items-start mt-8">
                    <div className="mt-8 space-y-2">
                        <p><strong>Student Name:</strong> {student?.name}</p>
                        <p><strong>Registration Number:</strong> {student?.roll_number ||'Not assigned yet'}</p>
                        <p><strong>Exam Center:</strong> {center?.center_name}</p>
                    </div>
                    {studentImage ? (
                        <img src={studentImage} alt="Student" className="w-24 h-32 rounded border border-gray-400 object-cover" />
                    ) : (
                        <p>Loading image...</p>
                    )}
                </div>

                <h3 className="text-lg mt-8 mb-3 font-semibold">Exam Schedule</h3>
                <table className="min-w-full border-collapse border border-gray-400">
                    <thead>
                        <tr>
                            <th className="border border-gray-400 p-2">Subject</th>
                            <th className="border border-gray-400 p-2">Date</th>
                            <th className="border border-gray-400 p-2">Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subjects.map((subject, index) => (
                            <tr key={index}>
                                <td className="border border-gray-400 p-2">{subject.subject_name}</td>
                                <td className="border border-gray-400 p-2">{timetable.subjects[index]?.exam_date}</td>
                                <td className="border border-gray-400 p-2">
                                    {convertTo12HourFormat(timetable.subjects[index]?.from_time)} to {convertTo12HourFormat(timetable.subjects[index]?.to_time)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="mt-8">
                    <p className="font-semibold">Note:</p>
                    <ul className="list-disc ml-5 space-y-2">
                        <li>Without identification card, candidates shall not be allowed to write the examination.</li>
                        <li>Carrying mobile phones into the examination is strictly prohibited.</li>
                        <li>Attend the examination as per the dates mentioned above.</li>
                    </ul>
                </div>

                <div className="flex justify-between items-center mt-12">
                    <div className="text-left">
                        <p className="font-semibold">Student's Signature:</p>
                        <p className="mt-4">___________________</p>
                    </div>
                    <div className="text-right">
                        <p className="font-semibold">Sd/-</p>
                        <p>Secretary</p>
                    </div>
                </div>
            </div>
            <AddButton
                onClick={handlePrint}
            >
                Print Hall Ticket
            </AddButton>
        </div>
    );
};

export default ViewHallticketComponent;