import React, { useState, useEffect } from "react";
import {
  employees,
  disableStaff,
  changePasswordByAdmin,
} from "../../Api/admin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faBan, faUnlockAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  PageWrapper,
  StyledTable,
  Form,
  FilterWrapper,
  SearchWrapper,
  NavButton,
  PageIndicator,
  AddButton,
  FilterSelect,
  FilterLabel,
  SearchInput,
  PageHeader,
  PageTitle,
  PageSubtitle,
  TableWrapper,
  NavWrapper,
} from "../UI/StyledComponents";

const StaffComponent = () => {
  const [employee, setEmployee] = useState([]);
  const [status, setStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const res = await employees();
        if (res.data.success) {
          const staffEmployees = res.data.data.staff.filter(
            (val) => val.role?.role_name === "STAFF"
          );
          setEmployee(staffEmployees);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmployeeData();
  }, [status]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleEdit = (id) => async () => {
    try {
      navigate(`/admin/editStaff/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisable = (id, currentStatus) => async () => {
    const action = currentStatus === "active" ? "disable" : "enable";
    const actionText = action === "disable" ? "Disable" : "Enable";
    const successMessage =
      action === "disable"
        ? "The employee has been disabled."
        : "The employee has been enabled.";

    const employeeToDisable = employee.find((emp) => emp.id === id);
    const employeeName = employeeToDisable
      ? employeeToDisable.full_name
      : "this employee";

    Swal.fire({
      title: `Change Status `,
      text: `Do you want to ${actionText.toLowerCase()} ${employeeName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `Yes, ${actionText.toLowerCase()} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await disableStaff(id);
          if (res.data.success) {
            setStatus(!status);
            Swal.fire(`${actionText}!`, successMessage, "success");
          }
        } catch (error) {
          Swal.fire(
            "Error!",
            "There was an issue changing the employee status.",
            "error"
          );
          console.log(error);
        }
      }
    });
  };

  const handleAdd = async () => {
    try {
      navigate("/admin/addStaff");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
  };

  const handleView = (id) => {
    navigate(`/admin/viewstaff/${id}`);
  };

  const handlePasswordChange = async (employeeId, employeeName) => {
    const { value: formValues } = await Swal.fire({
      title: `<span class="text-sm">Change Password for ${employeeName}?</span>`,
      html: `
        <input id="newPassword" class="swal2-input border border-gray-300 rounded px-2 py-1 text-sm" type="password" placeholder="New Password" required>
        <input id="confirmPassword" class="swal2-input border border-gray-300 rounded px-2 py-1 text-sm" type="password" placeholder="Confirm Password" required>
      `,
      focusConfirm: true,
      showCancelButton: true,
      confirmButtonText: "Update",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600",
        cancelButton: "bg-gray-300 text-gray-800 rounded px-4 py-2 hover:bg-gray-400",
      },
      width: "400px",
      padding: "20px",
      preConfirm: () => {
        const newPassword = document.getElementById("newPassword").value;
        const confirmPassword = document.getElementById("confirmPassword").value;

        if (newPassword !== confirmPassword) {
          Swal.showValidationMessage("Passwords do not match!");
          return false;
        }
        return { newPassword };
      },
    });

    if (formValues) {
      try {
        await changePasswordByAdmin(employeeId, formValues.newPassword, "STAFF");
        Swal.fire("Success!", "Password updated successfully!", "success");
      } catch (error) {
        Swal.fire("Error!", "Unable to update the password.", "error");
      }
    }
  };


  const filteredEmployees = employee.filter((val) => {
    const matchesSearchQuery =
      val.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.email.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesStatus =
      selectedStatus === "" || val.status === selectedStatus;
    return matchesSearchQuery && matchesStatus;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentEmployees = filteredEmployees.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredEmployees.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Staff</PageTitle>
        <PageSubtitle>View and Manage staff</PageSubtitle>
      </PageHeader>

      {employee.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No employees to display.</p>
          <AddButton type="button" onClick={handleAdd}>
            Add Staff
          </AddButton>
        </div>
      ) : (
        <>
          <Form>
            <FilterWrapper>
              <FilterLabel>Filter by Status</FilterLabel>
              <FilterSelect
                id="statusFilter"
                onChange={(e) => handleStatusFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </FilterSelect>
            </FilterWrapper>

            <SearchWrapper>
              <SearchInput
                placeholder="Search"
                required
                value={searchQuery}
                onChange={handleSearch}
              />
            </SearchWrapper>

            <AddButton type="button" onClick={handleAdd}>
              Add Staff
            </AddButton>
          </Form>

          {filteredEmployees.length === 0 ? (
            <div className="text-center">
              <p className="text-gray-700 mb-4">
                No matching employees to display.
              </p>
            </div>
          ) : (
            <>
              <TableWrapper>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>Sl no</th>
                      <th>Employee Name</th>
                      <th>Employee Email</th>
                      <th>Mobile Number</th>
                      <th>Date Added</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEmployees.length === 0 ? (
                      <tr>
                        <td colSpan="6" className="text-center">
                          No employees to display.
                        </td>
                      </tr>
                    ) : (
                      currentEmployees
                        .sort(
                          (a, b) =>
                            new Date(b.created_at) - new Date(a.created_at)
                        )
                        .map((val, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{val.full_name}</td>
                            <td>{val.email}</td>
                            <td>{val.mobile_number}</td>
                            <td>{formatDate(val.created_at)}</td>
                            <td
                              className={
                                val.status === "active"
                                  ? "text-green-500 "
                                  : "text-red-500 "
                              }
                            >
                              {val.status}
                            </td>
                            <td>
                              <button title='Edit' onClick={handleEdit(val.id)}>
                                <FontAwesomeIcon icon={faEdit} />
                              </button>
                              <button
                                title={
                                  val.status === "active" ? "Disable" : "Enable"
                                }
                                onClick={handleDisable(val.id, val.status)}
                              >
                                <FontAwesomeIcon
                                  icon={faBan}
                                  className={`text-lg ${
                                    val.status === "active"
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }`}
                                />
                              </button>
                              <button
                                onClick={() => handlePasswordChange(val.id, val.full_name)}
                              >
                                <FontAwesomeIcon icon={faUnlockAlt} />
                              </button>
                              <button
                                title="View Center"
                                className="relative bg-purple-500 text-white py-1 px-3 rounded"
                                onClick={() => handleView(val.id)}
                              >
                                <FontAwesomeIcon icon={faEye} />
                              </button>
                            </td>
                          </tr>
                        ))
                    )}
                  </tbody>
                </StyledTable>
              </TableWrapper>

              {totalPages > 1 && (
                <NavWrapper>
                  <NavButton
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </NavButton>
                  <PageIndicator>
                    Page {currentPage} of {totalPages}
                  </PageIndicator>
                  <NavButton
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </NavButton>
                </NavWrapper>
              )}
            </>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default StaffComponent;
