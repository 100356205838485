import Sidebar from "../../../Components/Admin/Sidebar";
import AddCourseComponent from "../../../Components/Admin/AddCourseComponent";
import {
  SidebarContainer,
  MainContent,
  Layout,
} from "../../../Components/UI/StyledComponents";
import NavComponent from "../../../Components/Admin/NavComponent";

const AddCourse = () => {
  return (
    <>
      <NavComponent style={{ marginBottom: "60px" }} />
      <Layout>
        <SidebarContainer>
          <Sidebar />
        </SidebarContainer>
        <MainContent>
          <AddCourseComponent />
        </MainContent>
      </Layout>
    </>
  );
};

export default AddCourse;
