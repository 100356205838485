import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { AddButton, DividerLine,ModalContainer, InputContainer,AddSubjectInput,AddSubjectLabel,FlexRow, Spacing20  } from '../UI/StyledComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { addSubject } from '../../Api/admin';
import { toast } from 'react-toastify';

const DragAndDrop = ({ selectedSubjects, setSelectedSubjects, sortedSubjects, onSubjectChange, refreshSubjects }) => {
    const [showForm, setShowForm] = useState(false);
    const [newSubjectName, setNewSubjectName] = useState("");
    const [newSubjectCode, setNewSubjectCode] = useState("");
    const [totalMarks, setTotalMarks] = useState(0);
    const [passMarks, setPassMarks] = useState(0);
    const [practicalMarks, setPracticalMarks] = useState(0);

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return;
        if (source.index === destination.index) return;
        const reorderedSubjects = Array.from(selectedSubjects);
        const [movedSubject] = reorderedSubjects.splice(source.index, 1);
        reorderedSubjects.splice(destination.index, 0, movedSubject);
        setSelectedSubjects(reorderedSubjects);
        onSubjectChange(reorderedSubjects);
    };

    const handleAddNewSubject = async () => {
        if (newSubjectName && newSubjectCode && totalMarks && passMarks) {
            try {
                const res = await addSubject(
                    newSubjectCode,
                    newSubjectName,
                    totalMarks,
                    passMarks,
                    practicalMarks
                );
                if (res.data.success) {
                    toast.success("New subject added successfully.");
                    setNewSubjectName("");
                    setNewSubjectCode("");
                    setTotalMarks(0);
                    setPassMarks(0);
                    setPracticalMarks(0);
                    setShowForm(false);
                    refreshSubjects();
                } else {
                    toast.error(res.data.message);
                }
            } catch (error) {
                toast.error("An error occurred while adding the subject.");
            }
        } else {
            toast.error("Please fill out all required fields.");
        }
    };

    return (
        <div className="bg-white shadow-lg rounded-lg p-4 ml-4" style={{ width: '700px', flexShrink: 0 }}>
            <h3 className="text-lg text-gray-700 font-semibold">Selected Subjects</h3>
            <DividerLine />
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="selectedSubjects">
                    {(provided) => (
                        <table
                            className="mt-2 w-full"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            <thead>
                                <tr className=" bg-[#fffbfb] border">
                                    <th className="p-2"></th>
                                    <th className="p-2 text-left">#</th>
                                    <th className="p-2 text-left text-md font-semibold ">Subject Code</th>
                                    <th className="p-2 text-left text-md font-semibold ">Subject Name</th>
                                    <th className="p-2 text-left text-md font-semibold ">Total Marks</th>
                                    <th className="p-2 text-left text-md font-semibold ">Pass Marks</th>
                                    <th className="p-2 text-left text-md font-semibold ">Practical Marks</th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedSubjects.length === 0 && (
                                    <tr>
                                        <td colSpan="7" className="p-2 text-center">No subjects selected</td>
                                    </tr>
                                )}
                                {selectedSubjects.map((subjectId, index) => {
                                    const subject = sortedSubjects.find((s) => s.id === subjectId);
                                    if (!subject) return null;
                                    return (
                                        <Draggable key={subjectId} draggableId={subjectId.toString()} index={index}>
                                            {(provided) => (
                                                <tr
                                                    className="bg-gray-100 hover:bg-gray-200 border-b border-gray-300 transition duration-200"
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    <td className="p-2 text-center" {...provided.dragHandleProps}>
                                                        <FontAwesomeIcon icon={faGripVertical} className="text-gray-600 cursor-grab" />
                                                    </td>
                                                    <td className="p-2">{index + 1}</td>
                                                    <td className="p-2">{subject.subject_code}</td>
                                                    <td className="p-2">{subject.subject_name}</td>
                                                    <td className="p-2">{subject.max_marks}</td>
                                                    <td className="p-2">{subject.min_marks}</td>
                                                    <td className="p-2">{subject.practical_marks}</td>
                                                </tr>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </tbody>
                        </table>
                    )}
                </Droppable>
            </DragDropContext>
            <DividerLine />
            
            <button
                onClick={() => setShowForm(!showForm)}
                style={{ backgroundColor: "#fbd6bd" }}
                className="px-4 py-2 mt-4 text-black rounded-lg"
            >
                {showForm ? "Cancel" : "Add New Subject"}
            </button>
            {showForm && (
                <ModalContainer className="mt-4">
                    <h3 className="text-md font-semibold mb-4">Add New Subject</h3>
                    <DividerLine />
                    <Spacing20/>
                    <FlexRow className="flex flex-row gap-4">
                        
                        <InputContainer className="flex-1">
                            <AddSubjectLabel  htmlFor="newSubjectCode" >Subject Code</AddSubjectLabel>
                            <AddSubjectInput
                                type="text"
                                id="newSubjectCode"
                                value={newSubjectCode}
                                onChange={(e) => setNewSubjectCode(e.target.value)}
                                required
                            />
                        </InputContainer>
                        <InputContainer className="flex-1">
                            <AddSubjectLabel  htmlFor="newSubjectName" >Subject Name</AddSubjectLabel>
                            <AddSubjectInput
                                type="text"
                                id="newSubjectName"
                                value={newSubjectName}
                                onChange={(e) => setNewSubjectName(e.target.value)}
                                required
                            />
                        </InputContainer>
                        <InputContainer className="flex-1">
                            <AddSubjectLabel  htmlFor="totalMarks" >Total Marks</AddSubjectLabel>
                            <AddSubjectInput
                                type="number"
                                id="totalMarks"
                                value={totalMarks}
                                onChange={(e) => setTotalMarks(e.target.value)}
                                required
                            />
                        </InputContainer>
                        <InputContainer className="flex-1">
                            <AddSubjectLabel  htmlFor="passMarks" >Pass Marks</AddSubjectLabel>
                            <AddSubjectInput
                                type="number"
                                id="passMarks"
                                value={passMarks}
                                onChange={(e) => setPassMarks(e.target.value)}
                                required
                            />
                        </InputContainer>
                        <InputContainer className="flex-1">
                            <AddSubjectLabel  htmlFor="practicalMarks" >Practical Marks</AddSubjectLabel>
                            <AddSubjectInput
                                type="number"
                                id="practicalMarks"
                                value={practicalMarks}
                                onChange={(e) => setPracticalMarks(e.target.value)}
                            />
                        </InputContainer>
                    </FlexRow>
                    <Spacing20/>
                    <AddButton
                        onClick={handleAddNewSubject}
                    >
                        Add Subject
                    </AddButton>
                </ModalContainer>
            )}
        </div>
    );
};

export default DragAndDrop;


