import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { addSession } from "../../Api/admin";
import {
  AddComponentForm,
  PageHeader,
  PageSubtitle,
  PageTitle,
  SubHeading,
  PageWrapper,
  DividerLine,
  Spacing20,
} from "../UI/StyledComponents";

const AddSessionComponent = () => {
  const [session_year, setSessionYear] = useState("");
  const [session_month, setSessionMonth] = useState("");
  const [issue_date, setIssueDate] = useState(Date);
  const navigate = useNavigate();

  const handleDateChange = (e) => {
    setIssueDate(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await addSession(session_month, session_year, issue_date);
      if (res.data.success) {
        toast.success("Successfully added session...");
        navigate("/staff/session");
      } else if (!res.data.success) {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Add Sessions </PageTitle>
        <PageSubtitle>add session and managing form</PageSubtitle>
      </PageHeader>

      <AddComponentForm onSubmit={handleSubmit}>
        <SubHeading>Add Session Form</SubHeading>
        <DividerLine></DividerLine>
        <Spacing20></Spacing20>
        <div className="mb-4">
          <label htmlFor="month">Session Month</label>
          <input
            type="text"
            id="month"
            name="month"
            value={session_month}
            onChange={(e) => setSessionMonth(e.target.value)}
            placeholder="Enter month (e.g., January)"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="year">Session Year</label>
          <input
            type="text"
            id="year"
            name="year"
            value={session_year}
            onChange={(e) => setSessionYear(e.target.value)}
            list="yearOptions"
            placeholder="Enter or select a year"
            required
          />
        </div>

        <label htmlFor="date">Marks Card Issue Date</label>
        <input
          type="date"
          id="date"
          name="date"
          value={issue_date}
          onChange={handleDateChange}
          required
        />
        <button type="submit">Submit</button>
      </AddComponentForm>
    </PageWrapper>
  );
};

export default AddSessionComponent;
