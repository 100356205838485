import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { courses, addStudent, centers, sessions } from "../../Api/admin";
import {
  AddComponentForm,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  Spacing20,
  SubHeading,
  DividerLine,
} from "../UI/StyledComponents";

const AddStudentByAdminComponent = () => {
  const [name, setName] = useState("");
  const [mobile_number, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [father_name, setFatherName] = useState("");
  const [mother_name, setMotherName] = useState("");
  const [date_of_birth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [course_id, setCourseId] = useState("");
  const [center_id, setCenterId] = useState("");
  const [session_id, setSessionId] = useState("");
  const [course, setCourses] = useState([]);
  const [centersList, setCentersList] = useState([]);
  const [sessionList, setSessions] = useState([]);
  const [image, setImage] = useState(null);
  const [previousMarksCard, setPreviousMarksCard] = useState(null);
  const [tc, setTc] = useState(null);
  const [addressProof, setAddressProof] = useState(null);
  const [casteCertificate, setCasteCertificate] = useState(null);
  const [lastSchool, setLastSchool] = useState("");
  const [caste, setCaste] = useState("");
  const [category, setCategory] = useState("");
  const [otherInfo, setOtherInfo] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      setErrorMessage(`File size should not exceed 5MB. File: ${file.name}`);
    } else {
      setErrorMessage("");
      setFile(file);
    }
  };

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const res = await courses();
        if (res.data.success) {
          setCourses(res.data.data.courses);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchCenterData = async () => {
      try {
        const res = await centers();
        if (res.data.success) {
          setCentersList(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchSessionData = async () => {
      try {
        const res = await sessions();
        if (res.data.success) {
          setSessions(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSessionData();
    fetchCourseData();
    fetchCenterData();
  }, []);

  useEffect(() => {
    setPassword(mobile_number);
  }, [mobile_number]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const role_name = "STUDENT";
      const formData = new FormData();
      formData.append("name", name);
      formData.append("mobile_number", mobile_number);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("father_name", father_name);
      formData.append("mother_name", mother_name);
      formData.append("date_of_birth", date_of_birth);
      formData.append("gender", gender);
      formData.append("address", address);
      formData.append("course_id", course_id);
      formData.append("center_id", center_id);
      formData.append("session_id", session_id);
      formData.append("role_name", role_name);
      formData.append("lastSchool", lastSchool);
      formData.append("caste", caste);
      formData.append("category", category);
      formData.append("otherInfo", otherInfo);
      if (image) formData.append("image", image);
      if (previousMarksCard)
        formData.append("previousMarksCard", previousMarksCard);
      if (tc) formData.append("tc", tc);
      if (addressProof) formData.append("addressProof", addressProof);
      if (casteCertificate)
        formData.append("casteCertificate", casteCertificate);
      const res = await addStudent(formData);
      if (res.data.success) {
        toast.success("Successfully added student...");
        navigate("/admin/student");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Admin Student Registration</PageTitle>
        <PageSubtitle>Register and manage students for any center</PageSubtitle>
      </PageHeader>

      <AddComponentForm onSubmit={handleSubmit}>
        <SubHeading>Student Registration Form</SubHeading>
        <DividerLine></DividerLine>
        <Spacing20></Spacing20>

        <div className="mb-4">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="fatherName">Father's Name</label>
          <input
            type="text"
            id="fatherName"
            name="fatherName"
            value={father_name}
            onChange={(e) => setFatherName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="motherName">Mother's Name</label>
          <input
            type="text"
            id="motherName"
            name="motherName"
            value={mother_name}
            onChange={(e) => setMotherName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="dateOfBirth">Date of Birth</label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            value={date_of_birth}
            onChange={(e) => setDateOfBirth(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="address">Address</label>
          <textarea
            id="address"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            rows="2"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="gender">Gender</label>
          <select
            id="gender"
            name="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="course">Course</label>
          <select
            id="course"
            name="course"
            value={course_id}
            onChange={(e) => setCourseId(e.target.value)}
            required
          >
            <option value="">Select Course</option>
            {course.map((val) => (
              <option key={val.id} value={val.id}>
                {val.course_name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="center">Center</label>
          <select
            id="center"
            name="center"
            value={center_id}
            onChange={(e) => setCenterId(e.target.value)}
            required
          >
            <option value="">Select Center</option>
            {centersList.map((val) => (
              <option key={val.id} value={val.id}>
                {val.center_name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="session">Session</label>
          <select
            id="session"
            name="session"
            value={session_id}
            onChange={(e) => setSessionId(e.target.value)}
            required
          >
            <option value="">Select Session</option>
            {sessionList.map((val) => (
              <option key={val.id} value={val.id}>
                {val.session_year} - {val.session_month}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="lastSchool">Last School Attended</label>
          <input
            type="text"
            id="lastSchool"
            name="lastSchool"
            value={lastSchool}
            onChange={(e) => setLastSchool(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="caste">Caste</label>
          <input
            type="text"
            id="caste"
            name="caste"
            value={caste}
            onChange={(e) => setCaste(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="category">Category</label>
          <select
            id="category"
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Category
            </option>
            <option value="General">Gen</option>
            <option value="OBC">OBC</option>
            <option value="SC">SC</option>
            <option value="ST">ST</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="otherInfo">Other Info (Note for board)</label>
          <textarea
            type="text"
            id="otherInfo"
            name="otherInfo"
            value={otherInfo}
            onChange={(e) => setOtherInfo(e.target.value)}
            rows="2"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="mobileNumber">Mobile Number</label>
          <input
            type="text"
            id="mobileNumber"
            name="mobileNumber"
            value={mobile_number}
            onChange={(e) => setMobileNumber(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            disabled
          />
        </div>

        <div className="mb-4">
          <label htmlFor="role_name">Role Name</label>
          <input
            type="text"
            id="role_name"
            name="role_name"
            value="STUDENT"
            disabled
          />
        </div>

        <div className="mb-4">
          <label htmlFor="image">Student Image</label>
          <input
            type="file"
            id="image"
            onChange={(e) => handleFileChange(e, setImage)}
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="previousMarksCard">Previous Marks Card</label>
          <input
            type="file"
            id="previousMarksCard"
            onChange={(e) => handleFileChange(e, setPreviousMarksCard)}
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="tc">TC/LC</label>
          <input
            type="file"
            id="tc"
            onChange={(e) => handleFileChange(e, setTc)}
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="addressProof">Address Proof</label>
          <input
            type="file"
            id="addressProof"
            onChange={(e) => handleFileChange(e, setAddressProof)}
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="casteCertificate">
            Caste Certificate {category === "General" ? "(Optional)" : "(Required)"}
          </label>
          <input
            type="file"
            id="casteCertificate"
            name="casteCertificate"
            onChange={(e) => setCasteCertificate(e.target.files[0])}
            required={category && category !== "General"}
          />
        </div>

        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        <button type="submit">Submit</button>
      </AddComponentForm>
    </PageWrapper>
  );
};

export default AddStudentByAdminComponent;
