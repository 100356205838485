import React, { useState, useRef, useEffect } from "react";
import logo from "../../Assets/logo.png";
import dashboardLogo from "../../Assets/dashboardLogo.svg";
import logoutLogo from "../../Assets/logoutLogo.svg";
import taskLogo from "../../Assets/centerLogo.svg";
import passwordLogo from "../../Assets/passwordLogo.svg";
import centerLogo from "../../Assets/centerLogo.svg";
import studentLogo from "../../Assets/studentLogo.svg";
import courseLogo from "../../Assets/courseLogo.svg";
import examLogo from "../../Assets/examLogo.svg";
import resultLogo from "../../Assets/resultLogo.svg";
import { useDispatch } from "react-redux";
import SubjectLogo from "../../Assets/subjectLogo.svg";
import sessionLogo from "../../Assets/sessionLogo.svg";
import {
  SidebarButton,
  SidebarLink,
  SidebarWrapper,
  ToggleButton,
  LogoWrapper,
} from "../UI/StyledComponents";
import PrintIcon from "../../Assets/printIcon.svg";
import correctionIcon from "../../Assets/correctionIcon.svg";
import timeTableIcon from "../../Assets/timeTableIcon.svg";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { staffLogout } from "../../Store/slice/authSlice";
import { logout } from "../../Api/auth";
import { jwtDecode } from "jwt-decode";

const Sidebar = () => {
  const { staffInfo } = useSelector((state) => state.auth);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isPrintMenuOpen, setIsPrintMenuOpen] = useState(false);
  const [privilegesArray, setPrivilegesArray] = useState([]);

  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const decoding = async () => {
      if (staffInfo) {
        const decodedToken = jwtDecode(staffInfo);
        if (decodedToken) {
          const privileges = decodedToken?.user?.privileges;
          const privilegesArray = privileges
            .split(",")
            .map((privilege) => privilege.trim());
          setPrivilegesArray(privilegesArray);
        }
      }
    };
    decoding();
  }, [staffInfo]);

  const handleCloseSidebar = () => {
    setOpenSidebar(false);
  };

  const handleLogout = async () => {
    const res = await logout();
    if (res?.data.success) {
      dispatch(staffLogout());
      toast.success("Logged out successfully.");
      navigate("/");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setOpenSidebar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const togglePrintSubMenu = () => {
    setIsPrintMenuOpen(!isPrintMenuOpen);
  };

  return (
    <>
      <ToggleButton
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        onClick={() => setOpenSidebar(!openSidebar)}
      >
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </ToggleButton>
      <SidebarWrapper
        id="logo-sidebar"
        ref={sidebarRef}
        aria-label="Sidebar"
        $openSidebar={openSidebar}
        onClick={handleCloseSidebar}
      >
        <LogoWrapper to="/staff/dashboard">
          <img src={logo} alt="ksc Logo" />
          <h1>Karnataka State Council</h1>
          <h2>ಕರ್ನಾಟಕ ಸ್ಟೇಟ್ ಕೌನ್ಸಿಲ್</h2>
        </LogoWrapper>

        <ul className="space-y-2 font-medium">
          <li>
            <SidebarLink to="/staff/dashboard/">
              <img src={dashboardLogo} alt="Dashboard Icon" />
              <span>Dashboard</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/staff/tasks/">
              <img src={taskLogo} alt="Task Icon" />
              <span>Tasks</span>
            </SidebarLink>
          </li>
          {privilegesArray.includes("Center Management") && (
            <li>
              <SidebarLink to="/staff/center" activeClassName="active">
                <img src={centerLogo} alt="Center Icon" />
                <span>Centers</span>
              </SidebarLink>
            </li>
          )}
          {privilegesArray.includes("Student Management") && (
            <li>
              <SidebarLink to="/staff/student" activeClassName="active">
                <img src={studentLogo} alt="Student Icon" />
                <span>Students</span>
              </SidebarLink>
            </li>
          )}
          {privilegesArray.includes("Exam Management") && (
            <li>
              <SidebarLink to="/staff/exam" activeClassName="active">
                <img src={examLogo} alt="Exam Icon" />
                <span>Exams</span>
              </SidebarLink>
            </li>
          )}
          {privilegesArray.includes("Course Management") && (
            <li>
              <SidebarLink to="/staff/course" activeClassName="active">
                <img src={courseLogo} alt="Course Icon" />
                <span>Courses</span>
              </SidebarLink>
            </li>
          )}
          {privilegesArray.includes("Subject Management") && (
            <li>
              <SidebarLink to="/staff/subject" activeClassName="active">
                <img src={SubjectLogo} alt="Course Icon" />
                <span>Subjects</span>
              </SidebarLink>
            </li>
          )}
          {privilegesArray.includes("Session Management") && (
            <li>
              <SidebarLink to="/staff/session" activeClassName="active">
                <img src={sessionLogo} alt="session icon" />
                <span>Sessions</span>
              </SidebarLink>
            </li>
          )}
          {privilegesArray.includes("Timetable Management") && (
            <li>
              <SidebarLink to="/staff/timetable" activeClassName="active">
                <img src={timeTableIcon} alt="timetable Icon" />
                <span>Timetables</span>
              </SidebarLink>
            </li>
          )}
          {privilegesArray.includes("Corrections") && (
            <li>
              <SidebarLink to="/staff/corrections" activeClassName="active">
                <img src={correctionIcon} alt="timetable Icon" />
                <span>Corrections</span>
              </SidebarLink>
            </li>
          )}
          {privilegesArray.includes("Printing Requests") && (
            <>
              <li>
                <SidebarLink
                  as="button"
                  onClick={togglePrintSubMenu}
                  activeClassName="active"
                >
                  <img src={PrintIcon} alt="Certificate Icon" />
                  <span>Printing requests</span>
                </SidebarLink>
              </li>
              {isPrintMenuOpen && (
                <ul>
                  <SidebarButton>
                    <SidebarLink
                      to="/staff/requests/original/"
                      activeClassName="active"
                    >
                      <img src={correctionIcon} alt="Correction Icon" />
                      <span>Original certificate</span>
                    </SidebarLink>
                  </SidebarButton>
                  <SidebarButton>
                    <SidebarLink
                      to="/staff/requests/duplicate/"
                      activeClassName="active"
                    >
                      <img src={resultLogo} alt="Result Icon" />
                      <span>Duplicate certificate</span>
                    </SidebarLink>
                  </SidebarButton>
                </ul>
              )}
            </>
          )}
          {privilegesArray.includes("Result Updation") && (
            <li>
              <SidebarLink to="/staff/results">
                <img src={resultLogo} alt="Result Icon" />
                <span>Results</span>
              </SidebarLink>
            </li>
          )}
          <li>
            <SidebarLink to="/staff/changePassword">
              <img src={passwordLogo} alt="Password Icon" />
              <span>Change password</span>
            </SidebarLink>
          </li>
          <li>
            <SidebarLink to="/" onClick={handleLogout}>
              <img src={logoutLogo} alt="Logout Icon" />
              <span>Logout</span>
            </SidebarLink>
          </li>
        </ul>
      </SidebarWrapper>
    </>
  );
};

export default Sidebar;
