import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledTable, TableWrapper, PageWrapper, PageHeader, PageSubtitle, PageTitle, AddButton, SearchInput, SearchWrapper, Form, NavWrapper, NavButton, PageIndicator } from '../UI/StyledComponents';
import { getProfileCorrections } from '../../Api/center';
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import UserImage from '../../Assets/userImage.png';

const ProfileCorrectionComponent = () => {
  const { centerInfo } = useSelector((state) => state.auth);
  const [searchQuery, setSearchQuery] = useState('');
  const [corrections, setCorrections] = useState([]);
  const [centerId, setCenterId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20)

  useEffect(() => {
    const decoding = async () => {
      if (centerInfo) {
        const decodedToken = jwtDecode(centerInfo);
        if (decodedToken && decodedToken.id) {
          setCenterId(decodedToken.id);
        }
      }
    };
    decoding();
  }, [centerInfo]);

  useEffect(() => {
    const fetchCorrectionData = async () => {
      try {
        if (centerId) {
          const res = await getProfileCorrections(centerId);
          if (res.data.success) {
            setCorrections(res.data.data.corrections);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCorrectionData();
  }, [centerId]);

  const navigate = useNavigate();

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const filteredCorrections = corrections.filter(row => {
    return (
      row.Student.name.toLowerCase().includes(searchQuery) ||
      row.Student.roll_number.toLowerCase().includes(searchQuery)
    );
  });
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentCorrections = filteredCorrections.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredCorrections.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const handleRequestForCorrection = () => {
    navigate('/center/addCorrection');
  };

  const correctionFieldLabels = {
    name: "Student Name",
    father_name: "Father's Name",
    mother_name: "Mother's Name",
    date_of_birth: "Date of Birth",
    address: "Address",
    email: "Email",
    mobile_number: "Mobile Number"
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Profile Corrections</PageTitle>
        <PageSubtitle>View and manage student profile corrections requests</PageSubtitle>
      </PageHeader>
      <Form>
        <SearchWrapper>
          <SearchInput
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search for name and roll number..."
          />
        </SearchWrapper>
        <AddButton onClick={handleRequestForCorrection}>Request for Correction</AddButton>
      </Form>
      {corrections.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No corrections to display.</p>
        </div>
      ) : currentCorrections.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No corrections found.</p>
        </div>
      ) : (
        <>
          <TableWrapper>
            <StyledTable>
              <thead>
                <tr>
                  <th className="px-4 py-2">Sl No</th>
                  <th className="px-4 py-2">Photo</th>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Roll Number</th>
                  <th className="px-4 py-2">Correction Field</th>
                  <th className="px-4 py-2">Old Value</th>
                  <th className="px-4 py-2">New Value</th>
                  {/* <th className="px-4 py-2">Status</th> */}
                </tr>
              </thead>
              <tbody className="text-sm">
                {currentCorrections.map((row, index) => (
                  <tr key={row.id} className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">
                      <img
                        src={row.Student.image[0] || UserImage}
                        alt="Profile"
                        className="w-10 h-10 rounded-full"
                      />
                    </td>
                    <td className="px-4 py-2">{row.Student.name}</td>
                    <td className="px-4 py-2">{row.Student.roll_number}</td>
                    <td className="px-4 py-2">
                      {correctionFieldLabels[row.correction_field] || row.correction_field}
                    </td>
                    <td className="px-4 py-2">
                      {row.correction_field === 'date_of_birth' ? formatDate(row.old_value) : row.old_value}
                    </td>
                    <td className="px-4 py-2">
                      {row.correction_field === 'date_of_birth' ? formatDate(row.correction_value) : row.correction_value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          </TableWrapper>
          {totalPages > 1 && (
            <NavWrapper>
              <NavButton
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </NavButton>
              <PageIndicator>
                Page {currentPage} of {totalPages}
              </PageIndicator>
              <NavButton
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </NavButton>
            </NavWrapper>
          )}
        </>
      )}

    </PageWrapper>
  );
};

export default ProfileCorrectionComponent;