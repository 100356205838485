import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { addSubject } from "../../Api/admin";
import {
  AddComponentForm,
  PageHeader,
  PageSubtitle,
  PageTitle,
  SubHeading,
  PageWrapper,
  DividerLine,
  Spacing20,
} from "../UI/StyledComponents";

const AddSubjectComponent = () => {
  const [subject_name, setSubjectName] = useState("");
  const [subject_code, setSubjectCode] = useState("");
  const [max_marks, setMaxMarks] = useState(0);
  const [min_marks, setMinMarks] = useState(0);
  const [practical_marks, setPracticalMarks] = useState(0);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await addSubject(
        subject_code,
        subject_name,
        max_marks,
        min_marks,
        practical_marks
      );
      if (res.data.success) {
        toast.success("Successfully added subject...");
        navigate("/admin/subject");
      } else if (!res.data.success) {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Subjects</PageTitle>
        <PageSubtitle>Add and manage subjects</PageSubtitle>
      </PageHeader>

      <AddComponentForm onSubmit={handleSubmit}>
        <SubHeading>Add Subject Form</SubHeading>
        <DividerLine></DividerLine>
        <Spacing20></Spacing20>
        <label htmlFor="subjectname">Subject Name</label>
        <input
          type="text"
          id="subjectname"
          name="subjectname"
          value={subject_name}
          onChange={(e) => setSubjectName(e.target.value)}
          required
        />

        <label htmlFor="subjectcode">Subject Code</label>
        <input
          type="text"
          id="subjectcode"
          name="subjectcode"
          value={subject_code}
          onChange={(e) => setSubjectCode(e.target.value)}
          required
        />

        <label htmlFor="maxmarks">Total Marks</label>
        <input
          type="number"
          id="maxmarks"
          name="maxmarks"
          value={max_marks}
          onChange={(e) => setMaxMarks(parseInt(e.target.value))}
          required
        />

        <label htmlFor="minmarks">Pass Marks</label>
        <input
          type="number"
          id="minmarks"
          name="minmarks"
          value={min_marks}
          onChange={(e) => setMinMarks(parseInt(e.target.value))}
          required
        />

        <label htmlFor="practicalmarks">Practical Marks</label>
        <input
          type="number"
          id="practicalmarks"
          name="practicalmarks"
          value={practical_marks}
          onChange={(e) => setPracticalMarks(parseInt(e.target.value))}
          required
        />

        <button type="submit">Submit</button>
      </AddComponentForm>
    </PageWrapper>
  );
};

export default AddSubjectComponent;


