import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  getCertificate,
  fetchStudent,
  fetchSessionByCourseId,
  fetchSubjectsOfCourses,
} from "../../Api/admin";
import { fetchResultsByRollNumber } from "../../Api/student";
import "./CertificateSSLCComponent.css";

const CertificateSSLCComponent = () => {
  const { id, printNumber } = useParams();
  const [rollNumber, setRollNumber] = useState("");
  const [student, setStudent] = useState();
  const [courseId, setCourseId] = useState("");
  const [session, setSession] = useState();
  const [result, setResult] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const printRef = useRef();

  const languageLabels = [
    "First Language",
    "Second Language",
    "Third Language",
  ];

  useEffect(() => {
    const fetchPrintRequest = async () => {
      try {
        const res = await getCertificate(
          id,
          Number(printNumber) === 0 ? "Original" : "Duplicate"
        );
        if (res.data.success) {
          setRollNumber(res.data.data.certificate.roll_number);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchPrintRequest();
  }, [id, printNumber]);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        if (rollNumber) {
          const res = await fetchStudent(rollNumber);
          if (res.data.success) {
            setStudent(res.data.data);
            setCourseId(res.data.data.course_id);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchStudentData();
  }, [rollNumber]);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        if (courseId) {
          const res = await fetchSessionByCourseId(courseId);
          if (res.data.success) {
            setSession(res.data.data.session);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchSessionData();
  }, [courseId]);

  useEffect(() => {
    const fetchSubjectData = async () => {
      try {
        if (courseId) {
          const res = await fetchSubjectsOfCourses(courseId);
          if (res.data.success) {
            setSubjects(res.data.data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchSubjectData();
  }, [courseId]);

  useEffect(() => {
    const fetchResultData = async () => {
      try {
        if (rollNumber) {
          const res = await fetchResultsByRollNumber(rollNumber);
          if (res.data.success) {
            setResult(res.data.data.result);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchResultData();
  }, [rollNumber]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatDateForCertificate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthFullNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const year = date.getFullYear();
    const shortDate = `${day < 10 ? "0" + day : day}-${
      monthNames[date.getMonth()]
    }-${year}`;
    const dayWords = [
      "",
      "First",
      "Second",
      "Third",
      "Fourth",
      "Fifth",
      "Sixth",
      "Seventh",
      "Eighth",
      "Ninth",
      "Tenth",
      "Eleventh",
      "Twelfth",
      "Thirteenth",
      "Fourteenth",
      "Fifteenth",
      "Sixteenth",
      "Seventeenth",
      "Eighteenth",
      "Nineteenth",
      "Twentieth",
      "Twenty First",
      "Twenty Second",
      "Twenty Third",
      "Twenty Fourth",
      "Twenty Fifth",
      "Twenty Sixth",
      "Twenty Seventh",
      "Twenty Eighth",
      "Twenty Ninth",
      "Thirtieth",
      "Thirty First",
    ];
    const dayInWords = dayWords[day];
    const numberToWords = (num) => {
      const ones = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ];
      const teens = [
        "",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      const tens = [
        "",
        "Ten",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];
      const thousands = ["", "Thousand"];
      if (num === 0) return "Zero";
      let word = "";
      if (Math.floor(num / 1000) > 0) {
        word += ones[Math.floor(num / 1000)] + " " + thousands[1] + " ";
        num %= 1000;
      }
      if (Math.floor(num / 100) > 0) {
        word += ones[Math.floor(num / 100)] + " Hundred ";
        num %= 100;
      }
      if (num > 10 && num < 20) {
        word += teens[num - 10] + " ";
      } else {
        if (Math.floor(num / 10) > 0) {
          word += tens[Math.floor(num / 10)] + " ";
          num %= 10;
        }
        if (num > 0) {
          word += ones[num] + " ";
        }
      }
      return word.trim();
    };
    const yearInWords = numberToWords(year);
    const longDate = `${dayInWords} ${
      monthFullNames[date.getMonth()]
    }, ${yearInWords}`;
    return `${shortDate} ${longDate}`;
  };

  const calculateOverallPercentage = () => {
    const totalMaxMarks = subjects.reduce(
      (sum, subject) => sum + (subject?.max_marks || 0),
      0
    );
    const totalObtainedMarks = result[0]?.results.reduce(
      (total, val) => total + (val?.totalMarks || 0),
      0
    );
    return ((totalObtainedMarks / totalMaxMarks) * 100).toFixed(2);
  };

  const getResultClassification = (percentage) => {
    if (percentage >= 85) return "DISTINCTION";
    if (percentage >= 60) return "FIRST";
    if (percentage >= 50) return "SECOND";
    if (percentage >= 35) return "PASS";
    return "FAIL";
  };

  const overallPercentage = calculateOverallPercentage();
  const resultClassification = getResultClassification(overallPercentage);

  const printHandler = async () => {
    if (!printRef.current) {
      console.error(
        "Content not found! Ensure 'printRef' is assigned to the correct element."
      );
      return;
    }
    try {
      const printIframe = document.createElement("iframe");
      printIframe.style.position = "absolute";
      printIframe.style.width = "0";
      printIframe.style.height = "0";
      printIframe.style.border = "none";
      document.body.appendChild(printIframe);
      const content = printRef.current.innerHTML;
      const printWindow = printIframe.contentWindow;
      const printDocument = printWindow.document;
      printDocument.open();
      printDocument.write(`
            <html>
              <head>
                <title>Print Certificate</title>
                <style>
                  body {
                    font-family: Arial, sans-serif;
  
                  }
      
      
                    .certificate-print{
                    margin-left: 65px;
                    margin-top:305px;
                    }
                    .studentName{
                    margin-bottom: 15px !important;
                    }
                    .marks-table{
                    margin-top: 10px;
                    }

                   table, .certificate-print{
                    width: 660px;
                    border-collapse: collapse;
                    
                  }
      
                  .marks-table td, .marks-table th {
                    border: 1px solid #000;
                    font-size: 10px;
                  }

                  .issueDate td, .issueDate th{
                    font-size: 10px;
                    padding: 4px;

                  }
                    .issueDate{
                    margin-top:-10px

                    }
                    .obtained-class p {
                   font-size: 10px;
                     line-height: 3px;
                      }
                     .obtained-class span {
                    font-size: 11px;
                  line-height: 3px;


                 }
                .studentAddress{
             padding-top: 30px;
                 font-size: 15px;
                 line-height: 6px;

                 }
                  @media print {
                    @page {
                      margin: 0;
                    }
     
                  }
                </style>
              </head>
              <body>
                <div class="certificate-container">
              ${content}
            </div>
                <script>
                  window.onload = function() {
                    window.print();
                  };
                  window.onafterprint = function() {
                    window.close();
                  };
                </script>
              </body>
            </html>
          `);
      printDocument.close();
    } catch (error) {
      console.error("Error during print:", error);
    }
  };

  return (
    <>
      <div className="certificate-container" ref={printRef}>
        <div className="certificate-print">
          <div className="certificate-content">
            {/* First Table with Student Details */}

            <div style={{ textAlign: "center", marginTop: "200px" }}>
              <p style={{ textAlign: "left", marginBottom: "20px" }}>
                Roll Number : {student?.roll_number}
              </p>
              <p style={{ fontSize: "12px", marginBottom: "10px" }}>
                This is to certify that the candidate mentioned below has{" "}
                {resultClassification === "FAIL" ? "FAILED" : "PASSED"} 10th
                examination with the following details
              </p>

              <h3 style={{ fontSize: "16px", fontWeight: "550" }}>
                {result[0]?.Exam?.exam_name}
              </h3>
            </div>
            <table className="certHeaderTable">
              <tr>
                <td style={{ width: "70%" }}>
                  <p className="studentName">
                    Student Name : {student?.name?.toUpperCase()}
                  </p>
                  <p>Father's Name : {student?.father_name?.toUpperCase()}</p>

                  <p>Mother's Name : {student?.mother_name?.toUpperCase()}</p>
                </td>
                <td style={{ verticalAlign: "top" }}>
                  <p>
                    Session : {session?.session_month} - {session?.session_year}
                  </p>
                  <p>Result : {resultClassification}</p>
                </td>
              </tr>
            </table>

            {/* Second Table with Marks Information */}

            <table className="marks-table">
              <thead>
                <tr>
                  <td
                    colSpan="5"
                    style={{
                      padding: "6px",
                      textAlign: "left",
                      fontSize: "16px",
                    }}
                  >
                    Date of Birth :{" "}
                    {formatDateForCertificate(student?.date_of_birth)}
                  </td>
                </tr>
                <tr>
                  <td
                    rowSpan="2"
                    style={{
                      width: "56px",
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Sl. No
                  </td>
                  <td
                    rowSpan="2"
                    style={{
                      width: "400px",
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Subjects
                  </td>
                  <td
                    colSpan="2"
                    style={{
                      width: "400px",
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Marks
                  </td>
                  <td
                    rowSpan="2"
                    style={{ textAlign: "center", fontSize: "16px" }}
                  >
                    Marks Obtained
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      height: "25px",
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Max
                  </td>
                  <td
                    style={{
                      height: "25px",
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Min
                  </td>
                </tr>
              </thead>
              <tbody>
                {subjects.map((subject, index) => (
                  <tr key={index} style={{ height: "25px" }}>
                    <td
                      style={{
                        padding: "2px",
                        textAlign: "center",
                        fontSize: "15px",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td style={{ fontSize: "15px" }}>
                      {index < 3
                        ? ` ${languageLabels[index]} : ${subject?.subject_name}`
                        : subject?.subject_name}
                    </td>
                    <td style={{ textAlign: "center", fontSize: "15px" }}>
                      {subject?.max_marks}
                    </td>
                    <td style={{ textAlign: "center", fontSize: "15px" }}>
                      {subject?.min_marks}
                    </td>
                    <td style={{ textAlign: "center", fontSize: "15px" }}>
                      {result[0]?.results[index]?.totalMarks}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    colSpan="2"
                    style={{ textAlign: "right", fontSize: "15px" }}
                  >
                    <strong>Total Marks</strong>
                  </td>
                  <td style={{ textAlign: "center", fontSize: "15px" }}>
                    {subjects.reduce(
                      (total, val) => total + (val?.max_marks || 0),
                      0
                    )}
                  </td>
                  <td style={{ textAlign: "center", fontSize: "15px" }}>
                    {subjects.reduce(
                      (total, val) => total + (val?.min_marks || 0),
                      0
                    )}
                  </td>
                  <td style={{ textAlign: "center", fontSize: "15px" }}>
                    {result[0]?.results.reduce(
                      (total, val) => total + (val?.totalMarks || 0),
                      0
                    )}
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan="4"
                    style={{
                      border: "none",
                    }}
                  ></td>
                  <td
                    colSpan="4"
                    style={{
                      textAlign: "center",
                      fontSize: "15px",
                      border: "none",
                    }}
                  >
                    {overallPercentage}%
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Third Table with Address and Issue Date */}

            <table className="issueDate">
              <tr>
                <td style={{ fontSize: "15px", width: "30%" }}>
                  Issue Date : {formatDate(session?.issue_date)}
                </td>

                <td style={{ width: "30%", textAlign: "center" }}>
                  <img
                    src={student?.image[0]}
                    alt="Profile"
                    style={{
                      width: "90px",
                      height: "90px",
                      objectFit: "contain",
                      margin: "auto",
                      display: "block",
                    }}
                  />
                </td>

                <td className="obtained-class" style={{ width: "40%" }}>
                  <span>CLASS OBTAINED</span>
                  <p>Distinction 85% and above</p>
                  <p>First Class 60% and below 85%</p>
                  <p>Second Class 50% and below 60%</p>
                  <p>Class Declared for those who pass in first attempt only</p>
                  <span>PASS IN EXAMINATION</span>
                  <p>35% Minimum Each Subject and 35% in aggregate</p>
                </td>
              </tr>
              <tr>
                <td
                  style={{ paddingTop: "30px", fontSize: "15px" }}
                  className="studentAddress"
                >
                  <p>{student?.center?.address_line1}</p>
                  <p>{student?.center?.address_line2}</p>
                  <p>{student?.center?.address_line3}</p>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={printHandler}
          style={{
            padding: "10px 20px",
            backgroundColor: "#FBD6BD",
            color: "black",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Print Certificate
        </button>
      </div>
    </>
  );
};

export default CertificateSSLCComponent;
