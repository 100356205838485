import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { subjects, addCourse, exams } from "../../Api/admin";
import {
  AddComponentForm,
  PageHeader,
  PageSubtitle,
  PageTitle,
  PageWrapper,
  Spacing20,
  SubHeading,
  DividerLine,
  CheckBoxInput,
  CheckBoxLabel,
  CheckBoxWrapper,
  CheckBoxContainer,
  Form,
  SearchWrapper,
} from "../UI/StyledComponents";
import DragAndDrop from "./DragAndDrop";

const AddCourseComponent = () => {
  const [course_name, setCourseName] = useState("");
  const [courseDuration, setCourseDuration] = useState(1);
  const [subject, setSubject] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [exam, setExams] = useState([]);
  const [examId, setExamId] = useState("");
  const [examType, setExamType] = useState("");
  const navigate = useNavigate();

  const fetchSubjectData = async () => {
    try {
      const res = await subjects();
      if (res.data.success) {
        setSubject(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSubjectData();
  }, []);

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const res = await exams();
        if (res.data.success) {
          setExams(res.data.data.exams);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchExamData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await addCourse(
        course_name,
        selectedSubjects,
        courseDuration,
        examId,
        examType
      );
      if (res.data.success) {
        toast.success("Successfully added course...");
        navigate("/staff/course");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSubjectChange = (subjectId) => {
    if (selectedSubjects.includes(subjectId)) {
      setSelectedSubjects(selectedSubjects.filter((id) => id !== subjectId));
    } else {
      setSelectedSubjects([...selectedSubjects, subjectId]);
    }
  };

  const handleSubjectsChange = (reorderedSubjects) => {
    setSelectedSubjects(reorderedSubjects);
  };

  const filteredSubjects = subject.filter(
    (sub) =>
      sub.subject_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      sub.subject_code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedSubjects = [...filteredSubjects].sort((a, b) => {
    const aSelectedIndex = selectedSubjects.indexOf(a.id);
    const bSelectedIndex = selectedSubjects.indexOf(b.id);
    if (aSelectedIndex !== -1 && bSelectedIndex === -1) {
      return -1;
    }
    if (aSelectedIndex === -1 && bSelectedIndex !== -1) {
      return 1;
    }
    if (aSelectedIndex !== -1 && bSelectedIndex !== -1) {
      return aSelectedIndex - bSelectedIndex;
    }
    return 0;
  });

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Courses</PageTitle>
        <PageSubtitle>Add and manage course</PageSubtitle>
      </PageHeader>
      <div className="flex" style={{ flex: 1 }}>
        <AddComponentForm onSubmit={handleSubmit}>
          <SubHeading>Course Registration Form</SubHeading>
          <DividerLine></DividerLine>
          <Spacing20></Spacing20>

          <label htmlFor="coursename">Course name</label>
          <input
            type="text"
            id="coursename"
            name="coursename"
            value={course_name}
            onChange={(e) => setCourseName(e.target.value)}
            required
          />

          <label htmlFor="courseduration">Course Duration (years)</label>
          <input
            type="number"
            id="courseduration"
            name="courseduration"
            value={courseDuration}
            onChange={(e) => setCourseDuration(e.target.value)}
            min="1"
            required
          />
          <label htmlFor="exam">Select Exam</label>
          <select
            id="exam"
            value={examId}
            onChange={(e) => setExamId(e.target.value)}
            required
          >
            <option value="" disabled>
              Select an exam
            </option>
            {exam.map((exam) => (
              <option key={exam.id} value={exam.id}>
                {exam.exam_name}
              </option>
            ))}
          </select>

          <label htmlFor="examType">Exam Type</label>
          <div className="flex items-center mt-2 mb-3">
            <label className="mr-4">
              <input
                type="radio"
                value="yearly"
                checked={examType === "yearly"}
                onChange={() => setExamType("yearly")}
                required
              />{" "}
              Yearly
            </label>
            <label>
              <input
                type="radio"
                value="semester"
                checked={examType === "semester"}
                onChange={() => setExamType("semester")}
                required
              />{" "}
              Semester
            </label>
          </div>

          <label htmlFor="description">Subjects</label>
          <Form>
            <SearchWrapper className="flex items-center">
              <div className="relative w-full">
                <input
                  type="search"
                  id="search-dropdown"
                  placeholder="Search by subject code or name"
                  required
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </div>
            </SearchWrapper>
          </Form>

          <CheckBoxContainer
            style={{ maxHeight: "300px", overflowY: "auto", padding: "10px" }}
          >
            {sortedSubjects.length > 0 ? (
              sortedSubjects.map((val) => (
                <CheckBoxWrapper
                  key={val.id}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <CheckBoxInput
                    type="checkbox"
                    id={val.id}
                    name="subjects"
                    value={val.id}
                    checked={selectedSubjects.includes(val.id)}
                    onChange={() => handleSubjectChange(val.id)}
                  />
                  <CheckBoxLabel
                    htmlFor={val.id}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "70%",
                      padding: "8px",
                      borderRadius: "4px",
                      backgroundColor: selectedSubjects.includes(val.id)
                        ? "#e0f7fa"
                        : "transparent",
                      transition: "background-color 0.3s",
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        selectedSubjects.includes(val.id)
                          ? "#b2ebf2"
                          : "#f0f0f0")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundColor =
                        selectedSubjects.includes(val.id)
                          ? "#e0f7fa"
                          : "transparent")
                    }
                  >
                    {selectedSubjects.includes(val.id) && (
                      <span
                        style={{
                          color: "#00796b",
                          fontWeight: "bold",
                          marginRight: "8px",
                        }}
                      >
                        {`${selectedSubjects.indexOf(val.id) + 1}. `}
                      </span>
                    )}
                    <span>
                      {val.subject_code} - {val.subject_name}
                    </span>
                  </CheckBoxLabel>
                </CheckBoxWrapper>
              ))
            ) : (
              <p>No subjects found.</p>
            )}
          </CheckBoxContainer>

          <DividerLine></DividerLine>
          <button type="submit">Submit</button>
        </AddComponentForm>

        <DividerLine />
        <DragAndDrop
          selectedSubjects={selectedSubjects}
          setSelectedSubjects={setSelectedSubjects}
          sortedSubjects={subject}
          onSubjectChange={handleSubjectsChange}
          refreshSubjects={fetchSubjectData}
        />
      </div>
    </PageWrapper>
  );
};

export default AddCourseComponent;
