import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchCenter } from "../../Api/admin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { GridContainer, GridItem } from "../UI/StyledComponents";

const ViewCenterComponent = () => {
  const { id } = useParams();
  const [centerDetails, setCenterDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const getCenterDetails = async () => {
      const res = await fetchCenter(id);
      if (res.data.success) {
        setCenterDetails(res.data.data);
      }
    };
    getCenterDetails();
  }, [id]);

  const openModal = (url) => {
    setSelectedImage(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  if (!centerDetails) {
    return <div>No center details available.</div>;
  }

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="bg-white shadow-md rounded-lg p-6 space-y-4 w-full">
        <h2 className="text-2xl font-bold mb-4 text-center">Center Profile</h2>

        <div className="flex items-center">
          <strong className="w-1/3">Center Name :</strong>
          <span className="w-2/3 text-gray-700">{centerDetails.center_name}</span>
        </div>
        <div className="flex items-center">
          <strong className="w-1/3">Address :</strong>
          <span className="w-2/3 text-gray-700">
            {centerDetails.address}, {centerDetails.address_line1}, {centerDetails.address_line2}, {centerDetails.address_line3}
          </span>
        </div>
        <div className="flex items-center">
          <strong className="w-1/3">Coordinator Name :</strong>
          <span className="w-2/3 text-gray-700">{centerDetails.coordinator_name}</span>
        </div>
        <div className="flex items-center">
          <strong className="w-1/3">Center Code :</strong>
          <span className="w-2/3 text-gray-700">{centerDetails.center_code}</span>
        </div>
        <div className="flex items-center">
          <strong className="w-1/3">Mobile Number :</strong>
          <span className="w-2/3 text-gray-700">{centerDetails.mobile_number}</span>
        </div>
        <div className="flex items-center">
          <strong className="w-1/3">Email :</strong>
          <span className="w-2/3 text-gray-700">{centerDetails.email}</span>
        </div>
        <div className="flex items-center">
          <strong className="w-1/3">Status :</strong>
          <span className={`w-2/3 ${centerDetails.status === "active" ? "text-green-500" : "text-red-500"}`}>
            {centerDetails.status}
          </span>
        </div>
        <div className="flex items-center">
          <strong className="w-1/3">Active From :</strong>
          <span className="w-2/3 text-gray-700">{formatDate(centerDetails.createdAt)}</span>
        </div>
        <div className="flex items-center">
          <strong className="w-1/3">Created By :</strong>
          <span className="w-2/3 text-gray-700">{centerDetails.created_by}</span>
        </div>

        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Verification Files</h3>
          {centerDetails.verificationFiles && centerDetails.verificationFiles.length > 0 ? (
            <GridContainer>
              {centerDetails.verificationFiles.map((url, index) => (
                <GridItem key={index}>
                  <img
                    src={url}
                    alt={`Verification File ${index + 1}`}
                    title={
                      index === 0
                        ? "Coordinator ID Proof"
                        : index === 1
                          ? "Registration Copy"
                          : "Center Photos"
                    }
                    className="w-full h-full object-contain"
                    onClick={() => openModal(url)}
                  />
                </GridItem>
              ))}
            </GridContainer>
          ) : (
            <p className="text-gray-500">No verification files available.</p>
          )}
        </div>


        {isModalOpen && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
            onClick={closeModal}
          >
            <div className="relative max-w-2xl max-h-[90vh] mx-auto p-4 bg-white rounded-lg shadow-lg">
              <button
                title="Close"
                onClick={closeModal}
                className="absolute top-0 right-0 mt-2 mr-2 text-white bg-red-600 rounded-full w-8 h-8 flex items-center justify-center"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
              <img
                src={selectedImage}
                alt="Enlarged Verification File"
                className="w-full h-auto max-h-[85vh] rounded-md"
              />
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default ViewCenterComponent;