import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { fetchCourse, subjects, editCourse, exams, addSubject } from "../../Api/admin";
import {
  PageWrapper,
  PageHeader,
  PageSubtitle,
  PageTitle,
  DividerLine,
  SearchWrapper,
  CheckBoxContainer,
  CheckBoxWrapper,
  CheckBoxInput,
  CheckBoxLabel,
  Form,
  Spacing20,
  AddComponentForm
} from "../UI/StyledComponents";

const EditCourseComponent = () => {
  const [course_name, setCourseName] = useState("");
  const [course_duration, setCourseDuration] = useState(1);
  const [subject, setSubject] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [exam, setExams] = useState([]);
  const [examId, setExamId] = useState("");
  const [examType, setExamType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newSubjectName, setNewSubjectName] = useState("");
  const [newSubjectCode, setNewSubjectCode] = useState("");
  const [totalMarks, setTotalMarks] = useState(0);
  const [passMarks, setPassMarks] = useState(0);
  const [practicalMarks, setPracticalMarks] = useState(0);

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchExamData = async () => {
      try {
        const res = await exams();
        if (res.data.success) {
          setExams(res.data.data.exams);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchExamData();
  }, []);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        if (id) {
          const res = await fetchCourse(id);
          if (res.data.success) {
            setCourseName(res.data.data.course.course_name);
            setCourseDuration(res.data.data.course.duration);
            setSelectedSubjects(res.data.data.course.subjectIds);
            setExamId(res.data.data.course.exam_id);
            setExamType(res.data.data.course.exam_type);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourseData();
  }, [id]);

  useEffect(() => {
    const fetchSubjectData = async () => {
      try {
        if (id) {
          const res = await subjects();
          if (res.data.success) {
            setSubject(res.data.data);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubjectData();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        const res = await editCourse(
          id,
          course_name,
          course_duration,
          selectedSubjects,
          examId,
          examType
        );
        if (res.data.success) {
          toast.success("Update successful...");
          navigate("/admin/course");
        } else if (!res.data.success) {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSubjectChange = (subject) => {
    if (selectedSubjects.includes(subject)) {
      setSelectedSubjects(selectedSubjects.filter((item) => item !== subject));
    } else {
      setSelectedSubjects([...selectedSubjects, subject]);
    }
  };

  const handleAddNewSubject = async () => {
    if (newSubjectName && newSubjectCode && totalMarks && passMarks) {
      try {
        const res = await addSubject(
          newSubjectCode,
          newSubjectName,
          totalMarks,
          passMarks,
          practicalMarks
        );
        if (res.data.success) {
          setSubject([...subject, res.data.data.subject]);
          toast.success("New subject added successfully.");
          setNewSubjectName("");
          setNewSubjectCode("");
          setTotalMarks(0);
          setPassMarks(0);
          setPracticalMarks(0);
          setShowModal(false);
        } else {
          toast.error(res.data.message);
        }
      } catch (error) {
        console.log(error);
        toast.error("An error occurred while adding the subject.");
      }
    } else {
      toast.error("Please fill out all required fields.");
    }
  };

  const filteredSubjects = subject.filter((sub) =>
    sub.subject_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    sub.subject_code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Edit Course Details</PageTitle>
        <PageSubtitle>View and manage course details</PageSubtitle>
      </PageHeader>
      <AddComponentForm className="mt-4" onSubmit={handleSubmit}>

        <h2>Center Registration Form</h2>
        <DividerLine />
        <Spacing20 />
        <div className="mb-4">
          <label htmlFor="coursename" className="block text-sm font-medium text-gray-700 mb-1">
            Course Name
          </label>
          <input
            type="text"
            id="coursename"
            value={course_name}
            onChange={(e) => setCourseName(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="courseduration" className="block text-sm font-medium text-gray-700 mb-1">
            Course Duration (Years)
          </label>
          <input
            type="number"
            id="courseduration"
            value={course_duration}
            onChange={(e) => setCourseDuration(e.target.value)}
            min="1"
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="exam" className="block text-sm font-medium text-gray-700 mb-1">
            Select Exam
          </label>
          <select
            id="exam"
            value={examId}
            onChange={(e) => setExamId(e.target.value)}
            className="block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
            required
          >
            <option value="" disabled>Select an exam</option>
            {exam.map((exam) => (
              <option key={exam.id} value={exam.id}>
                {exam.exam_name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Exam Type
          </label>
          <div className="flex items-center">
            <label className="mr-4">
              <input
                type="radio"
                name="examType"
                value="yearly"
                checked={examType === "yearly"}
                onChange={(e) => setExamType(e.target.value)}
                required
              />
              {" "}Yearly
            </label>
            <label>
              <input
                type="radio"
                name="examType"
                value="semester"
                checked={examType === "semester"}
                onChange={(e) => setExamType(e.target.value)}
                required
              />
              {" "}Semester
            </label>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Subjects
          </label>
          <Form>
            <SearchWrapper className="flex items-center">
              <input
                type="search"
                placeholder="Search by subject code or name"
                value={searchQuery}
                onChange={handleSearch}
                className="pl-12 pr-4 py-2 w-full text-sm rounded-full border border-gray-300"
              />
            </SearchWrapper>
          </Form>

          <CheckBoxContainer style={{ maxHeight: "200px", overflowY: "auto" }}>
            {filteredSubjects.length > 0 ? (
              filteredSubjects.map((val, index) => (
                <CheckBoxWrapper key={index}>
                  <CheckBoxInput
                    type="checkbox"
                    id={val.id}
                    checked={selectedSubjects.includes(val.id)}
                    onChange={() => handleSubjectChange(val.id)}
                  />
                  <CheckBoxLabel htmlFor={val.id}>
                    {val.subject_code} - {val.subject_name}
                  </CheckBoxLabel>
                </CheckBoxWrapper>
              ))
            ) : (
              <p>No subjects found.</p>
            )}
          </CheckBoxContainer>
        </div>

        <button
          type="submit"
          className="w-full px-4 py-2 bg-[#FBD6BD] text-black border-grey-300 text-sm font-medium rounded-lg hover:bg-[#FFEFE4]"
        >
          Submit
        </button>

        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="px-4 py-2 mt-4 bg-gray-600 text-white rounded-lg"
        >
          Add New Subject
        </button>
      </AddComponentForm>



      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-lg">
            <h2 className="text-xl font-bold mb-4">Add Subject Form</h2>
            <DividerLine></DividerLine>
            <div>
              <label htmlFor="newSubjectName">Subject Name</label>
              <input
                type="text"
                id="newSubjectName"
                value={newSubjectName}
                onChange={(e) => setNewSubjectName(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="newSubjectCode">Subject Code</label>
              <input
                type="text"
                id="newSubjectCode"
                value={newSubjectCode}
                onChange={(e) => setNewSubjectCode(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="totalMarks">Total Marks</label>
              <input
                type="number"
                id="totalMarks"
                value={totalMarks}
                onChange={(e) => setTotalMarks(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="passMarks">Pass Marks</label>
              <input
                type="number"
                id="passMarks"
                value={passMarks}
                onChange={(e) => setPassMarks(e.target.value)}
                required
              />
            </div>
            <div>
              <label htmlFor="practicalMarks">Practical Marks</label>
              <input
                type="number"
                id="practicalMarks"
                value={practicalMarks}
                onChange={(e) => setPracticalMarks(e.target.value)}
              />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleAddNewSubject}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </PageWrapper>
  );
};

export default EditCourseComponent;
