import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faBan } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";
import { subjects, disableSubject } from "../../Api/admin";
import {
  AddButton,
  NavButton,
  PageWrapper,
  FilterLabel,
  FilterWrapper,
  FilterSelect,
  SearchWrapper,
  SearchInput,
  StyledTable,
  TableWrapper,
  PageHeader,
  PageTitle,
  PageSubtitle,
  Form,
  NavWrapper,
  PageIndicator,
} from "../UI/StyledComponents";

const SubjectComponent = () => {
  const [subject, setSubject] = useState([]);
  const [status, setStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubjectData = async () => {
      try {
        const res = await subjects();
        if (res.data.success) {
          setSubject(res.data.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubjectData();
  }, [status]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleEdit = (id) => async () => {
    try {
      navigate(`/admin/editSubject/${id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDisable = (id, currentStatus, subjectName) => {
    const isCurrentlyActive = currentStatus === "active";
    const actionText = isCurrentlyActive ? "Disable" : "Enable";
    const successMessage = isCurrentlyActive
      ? "The subject has been successfully disabled."
      : "The subject has been successfully enabled.";

    Swal.fire({
      text: `Are you sure you want to ${actionText.toLowerCase()} the subject ${subjectName}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: isCurrentlyActive ? "#d33" : "#3085d6",
      cancelButtonColor: "#3085d6",
      confirmButtonText: `Yes, ${actionText.toLowerCase()} it!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await disableSubject(id);
          if (res.data.success) {
            setStatus(!status);
            Swal.fire(actionText, successMessage, "success");
          }
        } catch (error) {
          Swal.fire(
            "Error!",
            "There was an issue changing the subject status.",
            "error"
          );
          console.log(error);
        }
      }
    });
  };

  const handleAdd = async () => {
    try {
      navigate("/admin/addSubject");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusFilter = (status) => {
    setSelectedStatus(status);
    setCurrentPage(1);
  };

  const filteredSubjects = subject.filter((val) => {
    const matchesSearchQuery =
      val.subject_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      val.subject_code.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesStatus =
      selectedStatus === "" || val.status === selectedStatus;
    return matchesSearchQuery && matchesStatus;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentSubjects = filteredSubjects.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredSubjects.length / itemsPerPage);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <PageWrapper>
      <PageHeader>
        <PageTitle>Subjects</PageTitle>
        <PageSubtitle>View and Manage subject details</PageSubtitle>
      </PageHeader>

      {subject.length === 0 ? (
        <div className="text-center">
          <p className="text-gray-700 mb-4">No subjects to display.</p>
          <AddButton type="button" onClick={handleAdd}>
            Add Subject
          </AddButton>
        </div>
      ) : (
        <div>
          <Form>
            <FilterWrapper>
              <FilterLabel>Filter by Status</FilterLabel>
              <FilterSelect
                id="statusFilter"
                onChange={(e) => handleStatusFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </FilterSelect>
            </FilterWrapper>

            <SearchWrapper>
              <SearchInput
                placeholder="Search"
                required
                value={searchQuery}
                onChange={handleSearch}
              />
            </SearchWrapper>
            <AddButton type="button" onClick={handleAdd}>
              Add Subject
            </AddButton>
          </Form>

          {filteredSubjects.length === 0 ? (
            <div className="text-center">
              <p className="text-gray-700 mb-4">No matching subjects found.</p>
            </div>
          ) : (
            <>
              <TableWrapper>
                <StyledTable>
                  <thead>
                    <tr>
                      <th>Subject Code</th>
                      <th>Subject Name</th>
                      <th>Pass Marks</th>
                      <th>Total Marks</th>
                      <th>Practical Marks</th>
                      <th>Date Added</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentSubjects
                      .sort(
                        (a, b) =>
                          new Date(b.created_at) - new Date(a.created_at)
                      )
                      .map((val, index) => (
                        <tr key={index} className="hover:bg-gray-100">
                          <td>{val.subject_code}</td>
                          <td>{val.subject_name}</td>
                          <td>{val.min_marks}</td>
                          <td>{val.max_marks}</td>
                          <td>{val.practical_marks}</td>
                          <td>{formatDate(val.created_at)}</td>
                          <td
                            className={`px-4 py-2 border-b ${val.status === "active"
                              ? "text-green-500"
                              : "text-red-500"
                              } w-1/12`}
                          >
                            {val.status}
                          </td>
                          <td>
                            <button onClick={handleEdit(val.id)}>
                              <FontAwesomeIcon icon={faEdit} />
                            </button>
                            <button
                              title={
                                val.status === "active" ? "Disable" : "Enable"
                              }
                              onClick={() => handleDisable(val.id, val.status, val.subject_name)}
                            >
                              <FontAwesomeIcon
                                icon={faBan}
                                className={`text-lg ${val.status === "active"
                                  ? "text-green-500"
                                  : "text-red-500"
                                  }`}
                              />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </StyledTable>
              </TableWrapper>

              {totalPages > 1 && (
                <NavWrapper
                  style={{ width: "400px" }}
                  className="flex justify-between items-center mt-10 mx-auto"
                >
                  <NavButton
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </NavButton>
                  <PageIndicator className="text-xs">
                    Page {currentPage} of {totalPages}
                  </PageIndicator>
                  <NavButton
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </NavButton>
                </NavWrapper>
              )}
            </>
          )}
        </div>
      )}
    </PageWrapper>
  );
};

export default SubjectComponent;
